import React from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { Typography } from "@mui/material"

interface TextMarkdownProps {
  content: string
}

const TextMarkdown: React.FC<TextMarkdownProps> = ({ content }) => {
  return (
    <Typography variant="body1" component="div" sx={{ maxWidth: "100%" }}>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
    </Typography>
  )
}

export default TextMarkdown
