import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Fab,
  Avatar,
  Zoom,
  ThemeProvider,
  createTheme,
  CssBaseline,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PersonIcon from "@mui/icons-material/Person";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useMutation } from "@apollo/client";
import { motion, AnimatePresence } from "framer-motion";
import { graphql } from "@/gql";
import TextMarkdown from "../TextMarkdown/TextMarkdown";
import { usePathname } from "next/navigation";

const MESSAGE = graphql(`
  mutation GetAssistanceResponse($message: String!) {
    getAssistanceResponse(message: $message)
  }
`);

// Define types
interface Message {
  text: string;
  sender: "user" | "bot" | string;
  timestamp: Date;
}

// Fixed extremely high z-index to ensure the chat stays on top
const CHAT_Z_INDEX = 9999;

// Custom styled components
const ChatContainer = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isFullscreen",
})<{ isFullscreen: boolean }>(({ theme, isFullscreen }) => ({
  position: "fixed",
  bottom: isFullscreen ? 0 : 80,
  right: isFullscreen ? 0 : 24,
  width: isFullscreen ? "100%" : 380,
  height: isFullscreen ? "100vh" : 600,
  display: "flex",
  flexDirection: "column",
  borderRadius: isFullscreen ? 0 : 16,
  overflow: "hidden",
  boxShadow: theme.shadows[10],
  transition: "all 0.3s ease-in-out",
  zIndex: CHAT_Z_INDEX, // Fixed high z-index
  [theme.breakpoints.down("sm")]: {
    width: isFullscreen ? "100%" : "calc(100% - 48px)",
    height: isFullscreen ? "100vh" : 500,
    bottom: isFullscreen ? 0 : 70,
    right: isFullscreen ? 0 : 24,
  },
}));

const ChatHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const ChatMessages = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: theme.palette.background.default,
  },
  "&::-webkit-scrollbar-thumb": {
    background:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.2)"
        : "rgba(0,0,0,0.2)",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.3)"
        : "rgba(0,0,0,0.3)",
  },
}));

const MessageBubble = styled(motion.div, {
  shouldForwardProp: (prop) => prop !== "isUser",
})<{ isUser: boolean }>(({ theme, isUser }) => ({
  display: "flex",
  alignItems: "flex-start",
  maxWidth: "80%",
  alignSelf: isUser ? "flex-end" : "flex-start",
  gap: theme.spacing(1),
  flexDirection: isUser ? "row-reverse" : "row",
}));

const MessageContent = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isUser",
})<{ isUser: boolean }>(({ theme, isUser }) => ({
  padding: "8px 12px",
  borderRadius: 12,
  backgroundColor: isUser
    ? theme.palette.primary.main
    : theme.palette.background.paper,
  color: isUser
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary,
  position: "relative",
  boxShadow: theme.shadows[1],
  wordBreak: "break-word",
}));

const TimeStamp = styled(Typography)(({ theme }) => ({
  fontSize: "0.7rem",
  opacity: 0.7,
  marginTop: 4,
}));

const ChatInputArea = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  alignItems: "center",
}));

const TypingIndicator = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 4,
  padding: "8px 12px",
}));

const TypingDot = styled(motion.div)(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey[400]
      : theme.palette.grey[700],
}));

// Chat Button Container with high z-index
const ChatButtonContainer = styled(Box)(() => ({
  position: "fixed",
  bottom: 109,
  right: 30,
  zIndex: CHAT_Z_INDEX - 1, // Slightly lower than chat but still very high
}));

const initMessage =
  "Bonjour ! Je suis votre assistant virtuel. Comment puis-je vous aider aujourd'hui ?";
const defaultResponse =
  "Merci pour votre message. Puis-je vous aider davantage sur ce sujet ?";

// Main component
const ChatBot: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([
    {
      text: initMessage,
      sender: "bot",
      timestamp: new Date(),
    },
  ]);
  const [input, setInput] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [getBotResponse, { loading }] = useMutation(MESSAGE);

  const pathname = usePathname();

  // Create theme
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: {
            main: "#3f51b5",
          },
          secondary: {
            main: "#00F50C9C",
          },
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                "& .MuiOutlinedInput-root": {
                  borderRadius: 24,
                },
              },
            },
          },
        },
      }),
    [prefersDarkMode]
  );

  // Scroll to bottom when new messages appear
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  // Focus input when chat opens
  useEffect(() => {
    if (open && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  // Add event listener to ensure chat stays on top with keyboard open on mobile
  useEffect(() => {
    if (open) {
      const handleResize = () => {
        if (window.visualViewport) {
          const chatContainer = document.getElementById("chat-container");
          if (chatContainer && !isFullscreen) {
            chatContainer.style.bottom = `${
              window.visualViewport.offsetTop + 80
            }px`;
          }
        }
      };

      window.visualViewport?.addEventListener("resize", handleResize);
      return () => {
        window.visualViewport?.removeEventListener("resize", handleResize);
      };
    }
  }, [open, isFullscreen]);

  const sendMessage = async () => {
    if (!input.trim()) return;
    const newMessages = [
      ...messages,
      { text: input, sender: "user", timestamp: new Date() },
    ];
    setMessages(newMessages);
    setInput("");

    try {
      let botResponse: string = defaultResponse;
      const userMessage = input.toLowerCase();
      const response = await getBotResponse({
        variables: {
          message: userMessage,
        },
      });
      if (response.data?.getAssistanceResponse) {
        botResponse = response.data?.getAssistanceResponse;
      }
      setMessages([
        ...newMessages,
        { text: botResponse, sender: "bot", timestamp: new Date() },
      ]);
    } catch (error) {
      setMessages([
        ...newMessages,
        {
          text: "Désolé, j'ai rencontré un problème. Veuillez réessayer.",
          sender: "bot",
          timestamp: new Date(),
        },
      ]);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const formatTimeStamp = (date: Date): string => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const closeChat = () => {
    if (isFullscreen) {
      setIsFullscreen(false);
      setTimeout(() => setOpen(false), 300); // Delay closing to see animation
    } else {
      setOpen(false);
    }
  };

  // Add styles directly to the root div to force it above other elements
  useEffect(() => {
    if (open) {
      // Create or update a style element to ensure z-index hierarchies
      let style = document.getElementById("chat-z-index-style");
      if (!style) {
        style = document.createElement("style");
        style.id = "chat-z-index-style";
        document.head.appendChild(style);
      }

      style.innerHTML = `
        .MuiDrawer-root, .MuiAppBar-root { 
          z-index: ${CHAT_Z_INDEX - 100} !important; 
        }
        #chat-container, #chat-container-parent { 
          z-index: ${CHAT_Z_INDEX} !important;
        }
      `;

      return () => {
        if (style && !open) {
          document.head.removeChild(style);
        }
      };
    }
  }, [open]);

  if (pathname.includes("/billets")) return null;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/* Chat toggle button with correct z-index */}
      <ChatButtonContainer>
        <Zoom in={!open}>
          <Fab
            color="primary"
            aria-label="chat"
            onClick={() => setOpen(true)}
            sx={{
              boxShadow: 3,
              "&:hover": {
                transform: "scale(1.05)",
                transition: "transform 0.2s",
              },
            }}
          >
            <ChatIcon />
          </Fab>
        </Zoom>
      </ChatButtonContainer>

      {/* Chat interface with correct z-index */}
      <Box
        id="chat-container-parent"
        sx={{ position: "fixed", bottom: 0, right: 0, zIndex: CHAT_Z_INDEX }}
      >
        <AnimatePresence>
          {open && (
            <ChatContainer
              id="chat-container"
              elevation={6}
              isFullscreen={isFullscreen}
            >
              <ChatHeader>
                <Box display="flex" alignItems="center" gap={1}>
                  <Avatar
                    sx={{
                      bgcolor: "primary.dark",
                      width: 36,
                      height: 36,
                    }}
                  >
                    <SmartToyIcon fontSize="small" sx={{ color: "white" }} />
                  </Avatar>
                  <Typography variant="h6" fontWeight={500}>
                    Assistant
                  </Typography>
                </Box>
                <Box display="flex">
                  <Tooltip
                    title={
                      isFullscreen ? "Quitter le plein écran" : "Plein écran"
                    }
                  >
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={toggleFullscreen}
                      aria-label="toggle fullscreen"
                    >
                      {isFullscreen ? (
                        <FullscreenExitIcon />
                      ) : (
                        <FullscreenIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={closeChat}
                    aria-label="close chat"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </ChatHeader>

              <ChatMessages>
                {messages.map((msg, index) => (
                  <MessageBubble
                    key={index}
                    isUser={msg.sender === "user"}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Avatar
                      sx={{
                        bgcolor:
                          msg.sender === "user"
                            ? "secondary.main"
                            : "primary.main",
                        width: 36,
                        height: 36,
                      }}
                    >
                      {msg.sender === "user" ? (
                        <PersonIcon fontSize="small" sx={{ color: "white" }} />
                      ) : (
                        <SmartToyIcon
                          fontSize="small"
                          sx={{ color: "white" }}
                        />
                      )}
                    </Avatar>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems:
                          msg.sender === "user" ? "flex-end" : "flex-start",
                      }}
                    >
                      <MessageContent isUser={msg.sender === "user"}>
                        <TextMarkdown content={msg.text} />
                      </MessageContent>
                      <TimeStamp
                        variant="caption"
                        sx={{
                          textAlign: msg.sender === "user" ? "right" : "left",
                        }}
                      >
                        {formatTimeStamp(msg.timestamp)}
                      </TimeStamp>
                    </Box>
                  </MessageBubble>
                ))}
                {loading && (
                  <MessageBubble isUser={false}>
                    <Avatar
                      sx={{
                        bgcolor: "primary.main",
                        width: 36,
                        height: 36,
                      }}
                    >
                      <SmartToyIcon fontSize="small" />
                    </Avatar>
                    <MessageContent isUser={false}>
                      <TypingIndicator>
                        <TypingDot
                          animate={{ y: [0, -5, 0] }}
                          transition={{
                            repeat: Infinity,
                            duration: 1,
                            delay: 0,
                          }}
                        />
                        <TypingDot
                          animate={{ y: [0, -5, 0] }}
                          transition={{
                            repeat: Infinity,
                            duration: 1,
                            delay: 0.2,
                          }}
                        />
                        <TypingDot
                          animate={{ y: [0, -5, 0] }}
                          transition={{
                            repeat: Infinity,
                            duration: 1,
                            delay: 0.4,
                          }}
                        />
                      </TypingIndicator>
                    </MessageContent>
                  </MessageBubble>
                )}
                <div ref={messagesEndRef} />
              </ChatMessages>

              <ChatInputArea>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Écrivez votre message..."
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyPress={handleKeyPress}
                  inputRef={inputRef}
                  multiline
                  maxRows={3}
                  size="small"
                  InputProps={{
                    sx: { py: 0.5, px: 2, minHeight: "50px" },
                  }}
                />
                <IconButton
                  color="primary"
                  onClick={sendMessage}
                  disabled={!input.trim() || loading}
                  sx={{
                    bgcolor:
                      input.trim() && !loading
                        ? "primary.main"
                        : "action.disabledBackground",
                    color:
                      input.trim() && !loading
                        ? "primary.contrastText"
                        : "action.disabled",
                    "&:hover": {
                      bgcolor:
                        input.trim() && !loading
                          ? "primary.dark"
                          : "action.disabledBackground",
                    },
                    width: 44,
                    height: 44,
                    transition: "all 0.2s ease-in-out",
                    transform:
                      input.trim() && !loading ? "scale(1)" : "scale(0.95)",
                  }}
                >
                  <SendIcon />
                </IconButton>
              </ChatInputArea>
            </ChatContainer>
          )}
        </AnimatePresence>
      </Box>
    </ThemeProvider>
  );
};

export default ChatBot;
